/* eslint-disable no-unused-vars */
import React from 'react'

export const DateTimeFormat = (date) => {
    const DATE_TIME_OPTIONS = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: 'numeric',
        minute: 'numeric',
    }
    return (
        new Intl.DateTimeFormat("en-US", DATE_TIME_OPTIONS).format(new Date(date))
    )
}

export const DateFormat = (date) => {
    const DATE_OPTIONS = {
        year: "numeric",
        month: "short",
        day: "2-digit",
    }
    return (
        new Intl.DateTimeFormat("en-US", DATE_OPTIONS).format(new Date(date))

    )
}
export const MonthYearFormat = (date) => {
    const DATE_OPTIONS = {
        year: "numeric",
        month: "short",
    }
    return (
        new Intl.DateTimeFormat("en-US", DATE_OPTIONS).format(new Date(date))

    )
}

export const formatDate = (newValue) =>{
    const localDate = new Date(newValue);
    const timezoneOffset = localDate.getTimezoneOffset(); // in minutes
    const normalizedDate = new Date(localDate.getTime() - timezoneOffset * 60 * 1000);
    const finalDate = normalizedDate.toISOString().split("T")[0];
    return finalDate
}